import React from 'react'

const Last = () => {
  return (
    <>
        <div className='last'>
            <div className='dash-9'></div>
            <span className='exp'>Dùng máy tính để có trải nghiệm tốt nhất .</span>
            <div className='last-name'>
                <span className='van'>Văn Nguyễn</span>
                <span className='coppy'>© 2024 Iragaki. All rights reserved!</span>

            </div>
        </div>
      
    </>
  )
}

export default Last
